<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-form
      ref="paymentForm"
      v-model="validPayment"
      lazy-validation
    >
      <v-card>
        <v-card-title class="title">
          Corporate Dealer
        </v-card-title>
        <v-card-text>
          <v-container grid-list-xs>
            <v-layout
              row
              wrap
            >
              <v-flex xs6>
                <v-select
                  v-model="dealer"
                  :hint="hint"
                  :items="dealers"
                  :menu-props="{ maxHeight:'270' }"
                  :rules="[ val => !!dealer || 'Corporate dealer required!' ]"
                  item-text="dealer"
                  item-value="name"
                  label="Select corporate dealer"
                  required
                  return-object
                  persistent-hint
                  validate-on-blur
                >
                  <template
                    slot="item"
                    slot-scope="data"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title v-html="data.item.dealer" />
                      <v-list-tile-sub-title v-html="data.item.name" />
                    </v-list-tile-content>
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
            <v-layout
              row
              wrap
            >
              <v-flex xs6>
                <v-text-field
                  v-model="erecharge"
                  :rules="erechargeRules"
                  label="E-recharge balance"
                  disabled
                />
              </v-flex>
              <v-flex xs2>
                <v-btn
                  :disabled="!dealer"
                  @click="addErechargeDialog = true"
                >
                  Add E-recharge
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-title class="title">
          Load Payment File
        </v-card-title>
        <v-card-text>
          <v-container grid-list-xs>
            <v-layout
              row
              wrap
            >
              <v-flex xs6>
                <v-text-field
                  v-model="selectedFile"
                  :rules="fileRules"
                  label="Selected file"
                  disabled
                />
              </v-flex>
              <v-flex xs2>
                <v-btn
                  :disabled="!dealer"
                  @click="pickFile"
                >
                  Select File
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
          <input
            ref="csv"
            type="file"
            style="display: none"
            @change.prevent="validFileMimeType"
          >
        </v-card-text>
        <v-data-table
          v-if="items.length"
          :headers="paymentHeaders"
          :items="items"
          item-key="msisdn"
        >
          <template #items="props">
            <tr>
              <td class="text-xs-left">
                {{ props.item.msisdn }}
              </td>
              <td class="text-xs-right">
                {{ props.item.amount }}
              </td>
              <td
                v-if="props.item.status === 1"
                class="text-xs-center"
              >
                <v-icon color="success">
                  mdi-check
                </v-icon>
              </td>
              <td
                v-else-if="props.item.status === 2"
                class="text-xs-center"
              >
                <v-icon color="error">
                  mdi-close
                </v-icon>
              </td>
              <td
                v-else
                class="text-xs-center"
              >
                {{ props.item.status }}
              </td>
            </tr>
          </template>
          <template #footer>
            <tr>
              <td class="text-xs-right">
                Required E-recharge:
              </td>
              <td class="text-xs-right primary--text">
                {{ totalAmount }}
              </td>
              <td>&nbsp;</td>
            </tr>
          </template>
        </v-data-table>
        <v-card-text>
          <v-alert
            :value="paymentAlert"
            :type="paymentAlertType"
            transition="scale-transition"
          >
            {{ paymentAlertMessage }}
          </v-alert>
        </v-card-text>
        <v-card-actions v-if="items.length">
          <v-spacer />
          <v-btn
            :loading="loading"
            :disabled="loading || processed"
            @click="processPayment"
          >
            Process Payment
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-dialog
      v-model="addErechargeDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          Add E-Recharge
        </v-card-title>
        <v-alert
          :value="alert"
          type="error"
          transition="scale-transition"
        >
          {{ alertMessage }}
        </v-alert>
        <v-alert
          :value="!alert"
          type="info"
        >
          Please ensure that the dealer has remitted the payment to the company before adding e-recharge to his OneSYS account.
        </v-alert>
        <v-card-text>
          <v-form
            ref="addErechargeForm"
            v-model="validErechargeValue"
            lazy-validation
          >
            <v-container grid-list-xs>
              <v-layout
                row
                wrap
              >
                <v-flex xs12>
                  <v-text-field
                    v-model.lazy="addErechargeAmount"
                    v-money="money"
                    prefix="RM "
                    validate-on-blur
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click="cancelAddErecharge()"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="addErechargeAmount <= 0"
            color="primary"
            flat
            @click="addErecharge()"
          >
            Add E-Recharge
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="10000"
      :color="snackbarColor"
      top
    >
      {{ snackbarText }}
      <v-btn
        flat
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </panel>
</template>

<script>
import Papa from 'papaparse'
import mimeTypes from 'mime-types'
import { createGetParams, createPostParams } from '@/rest'

const formTitle = 'Payments'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      addErechargeAmount: 0,
      addErechargeDialog: false,
      alert: false,
      alertMessage: null,
      breadcrumbs: [
        {
          text: 'Operation Kits', disabled: false, to: '/operation/operation_kits',
        },
        {
          text: 'Corporate Kits', disabled: false, to: '/operation/corporate_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      csv: null,
      dealer: null,
      dealers: [],
      erecharge: 0.00,
      erechargeRules: [],
      fileMimeTypes: ['text/csv', 'text/x-csv', 'application/vnd.ms-excel', 'text/plain'],
      hint: null,
      items: [],
      isValidFileMimeType: false,
      loading: false,
      money: {},
      paymentAlert: false,
      paymentAlertMessage: null,
      paymentAlertType: 'info',
      paymentHeaders: [
        {
          text: 'Msisdn',
          align: 'left',
          sortable: false,
          value: 'msisdn',
        },
        {
          text: 'Amount',
          align: 'right',
          sortable: false,
          value: 'amount',
        },
        {
          text: 'Payment Status',
          align: 'center',
          sortable: false,
          value: 'status',
        },
      ],
      fileRules: [],
      selectedFile: null,
      snackbar: false,
      snackbarText: null,
      snackbarColor: 'success',
      target: 0,
      title: formTitle,
      totalAmount: 0.00,
      validErechargeValue: true,
      validPayment: true,
    }
  },
  computed: {
    showErrorMessage () {
      return this.selectedFile && !this.isValidFileMimeType
    },
    processed () {
      return this.target >= this.items.length
    },
  },
  watch: {
    dealer: function (val) {
      if (val) {
        this.hint = val.name
        this.getDealerErecharge(val.id)
      }
    },
    csv: function (val) {
      this.target = 0
      this.totalAmount = 0.00
      this.paymentAlert = false

      if (!val) {
        this.items = []
        return
      }

      const items = []
      let totalAmount = 0.00
      val.data.forEach(source => {
        const item = {
          msisdn: source[0],
          amount: source[1],
          status: null,
        }

        totalAmount += Number(source[1])
        items.push(item)
      })

      this.items = items
      this.totalAmount = Number(totalAmount).toFixed(2)
    },
  },
  mounted: function () {
    this.fileRules.push(() => !this.showErrorMessage || 'Invalid file type!')
    this.erechargeRules.push((val) => val >= this.totalAmount || 'Insufficient balance!')
    this.getCorporateDealers()
  },
  methods: {
    cancelAddErecharge: function () {
      this.addErechargeDialog = false
      this.alert = false
    },
    async addErecharge () {
      if (!this.$refs.addErechargeForm.validate()) return
      try {
        const params = createPostParams({
          action: 'addErecharge',
          payload: {
            id: this.dealer.id,
            amount: this.addErechargeAmount.replace(/ /g, ''),
          },
        })
        await this.$rest.post('postAction.php', params)

        this.addErechargeDialog = false
        this.snackbarText = 'You have successfully added RM ' + this.addErechargeAmount + ' e-recharge into account ' + this.dealer.dealer
        this.snackbar = true
        this.getDealerErecharge(this.dealer.id)
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.alertMessage = message
        this.alert = true
      }
    },
    getDealerErecharge: function (id) {
      const params = createGetParams({
        id,
        erecharge: true,
      })

      this.$rest.get('getDealerResources.php', params)
        .then(function (response) {
          this.erecharge = response.data.erecharge
        }.bind(this)).catch(() => {
          // (error)
        })
    },
    getCorporateDealers: function () {
      this.$rest.get('getCorporateDealers.php', createGetParams({}))
        .then(function (response) {
          for (const item of response.data) {
            this.dealers.push(item)
          }
        }.bind(this)).catch(() => {
          // (error)
        })
    },
    async processPayment () {
      this.paymentAlert = false
      if (!this.$refs.paymentForm.validate()) {
        this.snackbarText = 'Please correct the errors before processing the payment.'
        this.snackbarColor = 'error'
        this.snackbar = true
        return
      }

      this.loading = true
      for (let index = 0; index < this.items.length; index++) {
        if (this.items[index].status !== 1) {
          try {
            const params = createPostParams({
              action: 'payCorporateBill',
              payload: {
                id: this.dealer.id,
                msisdn: this.items[index].msisdn,
                amount: this.items[index].amount,
              },
            })
            await this.$rest.post('postAction.php', params)
            this.items[index].status = 1
            this.target++
          } catch (error) {
            let message = error.message
            if (error.response) {
              message += ': ' + error.response.data
            }
            this.items[index].status = 2
            this.paymentAlertMessage = message
            this.paymentAlertType = 'error'
            this.paymentAlert = true
            break
          }
        }
      }
      this.getDealerErecharge()
      this.loading = false
      if (this.processed) {
        this.paymentAlertMessage = 'The payments have been successfully processed!'
        this.paymentAlertType = 'success'
        this.paymentAlert = true
      }
    },
    load () {
      const _this = this
      this.readFile((output) => {
        _this.csv = Papa.parse(output, { skipEmptyLines: true })
      })
    },
    readFile (callback) {
      const file = this.$refs.csv.files[0]
      if (file) {
        const reader = new FileReader()
        reader.readAsText(file, 'UTF-8')
        reader.onload = function (evt) {
          callback(evt.target.result)
        }
        reader.onerror = function () {
        }
      }
    },
    pickFile () {
      this.$refs.csv.click()
    },
    validFileMimeType () {
      const file = this.$refs.csv.files[0]
      const mimeType = file.type === '' ? mimeTypes.lookup(file.name) : file.type
      if (file) {
        this.selectedFile = file.name
        this.isValidFileMimeType = this.validateMimeType(mimeType)
        if (this.isValidFileMimeType) {
          this.load()
        } else {
          this.items = []
          this.totalAmount = 0.00
          this.target = 0
        }
      } else {
        this.isValidFileMimeType = false
        this.selectedFile = null
      }
    },
    validateMimeType (type) {
      return this.fileMimeTypes.indexOf(type) > -1
    },
  },
}
</script>
